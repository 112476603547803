import React from "react";
import Layout from "../components/Layout";
import RightColumn from "../components/RightColumn";

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo10.jpg" sidebarImageAlt="">
        <div id="mainContent">
          <p>
            <strong>WBA Analytical Laboratories</strong> is a multi-discipline
            laboratory network which provides high-quality laboratory, research
            and&nbsp;technical services to a wide array of customers including
            those involved with live production, food production, feeds and
            ingredients, pet food and renewable fuels.{" "}
            <strong>WBA Analytical Laboratories</strong> has its headquarters in
            Springdale Arkansas, with additional laboratories in Arkansas,
            Mississippi, North Carolina, and Texas.
          </p>
        </div>

        <RightColumn />

        <br className="clearfloat" />

        <div className="blueModule">
          <div className="header-three">Technical Services</div>
          <ul>
            <li>Laboratory Training &amp; Auditing</li>
            <li>Sanitation Troubleshooting</li>
            <li>Customized Monitoring Programs</li>
            <li>Personnel Competency Check Sample Program</li>
          </ul>
        </div>

        <div className="blueModule">
          <div className="header-three">Research Services</div>
          <ul>
            <li>Pathogen Intervention Studies</li>
            <li>Product Validation</li>
            <li>Thermal Death Time</li>
            <li>Process Verifications</li>
            <li>Biomapping</li>
          </ul>
        </div>

        <div className="blueModule">
          <div className="header-three">Microbiology Testing</div>
          <ul>
            <li>Food Microbiology</li>
            <li>Sanitation Monitoring</li>
            <li>Shelf-life Studies</li>
            <li>Russian Export Testing</li>
            <li>PFGE</li>
            <li>AOAC Method Validation Participant</li>
          </ul>
        </div>

        <div className="blueModule">
          <div className="header-three">Chemistry Testing</div>
          <ul>
            <li>Proximate Analysis</li>
            <li>Analytical Chemistry</li>
            <li>Nutritional Labeling</li>
            <li>Russian Export Testing</li>
            <li>Residue Screening</li>
          </ul>
        </div>
      </Layout>
    );
  }
}
